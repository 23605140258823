


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { SubscriptionInfo } from '@/models/Subscription';
import dayjs from 'dayjs';

@Component
export default class SavedCardForm extends Vue {
  @Prop()
  msgSavedCardInfo!: SubscriptionInfo;
  year = dayjs()
    .format('YYYY')
    .substring(0, 2);
}
