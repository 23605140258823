import Vue from 'vue';
import * as api from '@/gen/api';
import { SubscriptionInfo } from '@/models/Subscription';

export class SubscriptionRepository {
  private async subscriptionApiClient() {
    const config = await Vue.prototype.$getConfigWithToken(Vue.prototype.$auth);
    return new api.SubscriptionApi(config);
  }

  // サブスク登録API
  async postSubscription(token: string, id: string) {
    const client = await this.subscriptionApiClient();
    try {
      // トークンが存在すれば合わせて送信
      const res = await client.postSubscription(
        token
          ? {
              pay_token: token,
              subscription_package_id: id
            }
          : {
              subscription_package_id: id
            }
      );
      return res.data;
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  // サブスク登録カード更新API
  async updateSubscriptionCard(token: string) {
    const client = await this.subscriptionApiClient();
    try {
      await client.updateSubscriptionCard({ pay_token: token });
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  // サブスク解約予約API
  async updateSubscriptionUnsubscribe(id: string) {
    const client = await this.subscriptionApiClient();
    try {
      await client.updateSubscriptionUnsubscribe({
        subscription_package_id: id
      });
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  // サブスク登録情報取得API
  async getSubscriptionInfo() {
    const client = await this.subscriptionApiClient();
    try {
      const res = await client.getSubscriptionInfo();
      return SubscriptionInfo.valueOf(res.data);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }
}
