import { ResSubscriptionInfo, SubscriptionContract } from '@/gen';

// SubscriptionInfo サブスク登録情報 API のレスポンスを受け取る
export class SubscriptionInfo {
  public constructor(
    readonly maskedCardNumber: string,
    readonly expiryYm: string,
    readonly contracts: Array<SubscriptionContract>
  ) {}

  static valueOf(data: ResSubscriptionInfo) {
    return new SubscriptionInfo(
      data.card_info.masked_card_number,
      data.card_info.expiry_ym,
      data.subscription_contracts
    );
  }

  // getSubscriptionInfoByPackageId 指定のパッケージ ID のサブスク登録情報を取得する
  public getSubscriptionInfoByPackageId(packageId: string) {
    return this.contracts.find(c => c.subscription_package_id === packageId);
  }

  // isAnyContractsToBeContinued いずれかのパッケージに登録中かつ自動更新フラグが「更新予定」であれば true を返却する
  get isAnyContractsToBeContinued() {
    return this.contracts.find(
      c =>
        new Date(c.start_dt) <= new Date() &&
        new Date() < new Date(c.end_dt) &&
        c.continue_flg
    )
      ? true
      : false;
  }

  // isSubscriber 指定のパッケージに登録中であれば true を返却する
  public isSubscriber(packageId: string) {
    const contract = this.getSubscriptionInfoByPackageId(packageId);
    if (contract) {
      return (
        new Date(contract.start_dt) <= new Date() &&
        new Date() < new Date(contract.end_dt)
      );
    }
    return false;
  }

  // getPayError 対象のサブスクパッケージの決済エラー区分を返却する
  public getPayError(packageId: string) {
    const contract = this.getSubscriptionInfoByPackageId(packageId);
    return contract ? contract.pay_error : '';
  }
}
