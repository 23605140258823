




import { Component, Vue, Watch } from 'vue-property-decorator';
import store from '@/store';
var issuedToken = '';

@Component
export default class GetSonyPaymentToken extends Vue {
  // getTokenRespondedの値変化をwatchし、tokenResponded = trueであれば画面へtokenを返却(issuedTokenに値が格納されたこを検知する)
  @Watch('getTokenResponded')
  returnToken(tokenResponded: boolean) {
    if (tokenResponded) {
      this.$store.commit('setTokenResponded', false);
      // spsvCreateTokenのタイムアウト判定
      if (issuedToken.length === 9) {
        this.$emit('handleToken', '', 'spsvCreateToken timeout');
      } else {
        this.$emit('handleToken', issuedToken, '');
      }
    }
  }

  // state.tokenRespondedの値変化を検知するgetter
  get getTokenResponded() {
    return this.$store.state.tokenResponded;
  }

  // 各決済画面で利用される関数
  getPaymentToken(
    cardNum: string,
    expYear: string,
    expMonth: string,
    securityCode: string
  ) {
    try {
      // @ts-ignore
      SpsvApi.spsvCreateToken(cardNum, expYear, expMonth, securityCode,'', '', '', '', ''); // eslint-disable-line
    } catch (e) {
      this.$emit('handleToken', '', e);
    }
  }
}

// setPaymentToken()をwindow関数として登録し、index.htmlからの呼び出しを可能とする。
interface Window {
  setPaymentToken(token: string): void;
}
declare var window: Window;

// issuedTokenに取得したtokenを格納し、Component側から検知できるようにstoreにflagを格納する。
window.setPaymentToken = (token: string) => {
  issuedToken = token;
  store.commit('setTokenResponded', true);
};
