






































































































































import filterUtils from '@/common/filterUtils';
import utils from '@/common/utils';
import dayjs from 'dayjs';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { InputCard } from '@/models/InputCard';

@Component
export default class CreditCardForm extends Vue {
  @PropSync('inputCard')
  syncedInputCard!: InputCard;
  // カード情報入力部分を非活性化
  @Prop()
  inputCardDisableFlag!: boolean;

  // カード情報入力部分のプレースホルダ
  readonly CREDIT_CARD_NUMBER_PLACEHOLDER = '0000000000000000';
  readonly CREDIT_CARD_EXP_PLACEHOLDER = '--';
  readonly CREDIT_CARD_SECURITY_CODE_PLACEHOLDER = '000';

  //カード情報入力部分のdata-vv-name
  readonly CARD_NUMBER = 'カード番号';
  readonly CARD_EXP_YEAR = 'カード有効期限:年';
  readonly CARD_EXP_MONTH = 'カード有効期限:月';
  readonly CARD_SECURITY_CODE = 'セキュリティコード';

  created() {
    this.initialize();
  }

  initialize() {
    this.validatorLocalize();
  }

  // バリデーションエラーのアラート文変更
  validatorLocalize() {
    const dict = {
      custom: {
        // クレジットカード番号
        cardNumber: {
          min: 'カード番号は11桁以上をご入力ください'
        },
        // セキュリティコード
        cardSecurityCode: {
          min: 'セキュリティコードは3桁以上をご入力ください'
        }
      }
    };
    this.$validator.localize('ja', dict);
  }

  //カード有効期限のプルダウン内容
  monthItems = utils.createNumberArrayWith0(12);
  pulldownYearNumber = Array.from(Array(20).keys());
  yearItems = this.pulldownYearNumber.map(elm => {
    return dayjs()
      .add(elm, 'year')
      .format('YY');
  });

  // 全角→半角変換
  formatText(target: string | undefined): string {
    return filterUtils.funcs.formatToHalfUpperCase(target || '');
  }

  // ユーザ入力情報のバリデーション
  async formatCardNumber() {
    this.syncedInputCard.number = this.formatText(this.syncedInputCard.number);
    await this.$nextTick();
    this.$validator.validate('cardNumber');
  }
  async formatCardSecurityCode() {
    this.syncedInputCard.securityCode = this.formatText(
      this.syncedInputCard.securityCode
    );
    await this.$nextTick();
    this.$validator.validate('cardSecurityCode');
  }
}
